import React from 'react';

import PageContainer from '../../js/components/PageContainer';
import Page from '../../js/pages/LoginPage';

Page.getLayout = (page) => (
  <PageContainer minimalHeader minimalFooter>
    {page}
  </PageContainer>
);

export default Page;
